<template lang="html">
  <v-container pa-0 fluid>
    <v-row dense>
      <v-col cols="12">
        <v-encounter-bar v-model="currentEncounterId" :encounterType="['AMB','IMP']" @encounterLoaded="onEncounterLoaded" @encounterUnloaded="onEncounterUnloaded"></v-encounter-bar>
      </v-col>
      <v-col cols="12" v-if="currentPrescription">
        <v-container>
          <v-row no-gutters align="center">
            <v-col>
              <span class="text-h6">{{ currentPrescription.scheduleDate }}</span>
            </v-col>
            <v-col class="text-right">
              <v-btn class="mr-1" @click="closePartial" color="primary">Partial</v-btn>
              <v-btn class="mr-1 white--text" @click="closeComplete" color="green darken-2">Complete</v-btn>
            </v-col>
          </v-row>
        </v-container>
        <v-tabs-group>
          <template v-slot:tabs>
            <v-tab key="prescription">ใบสั่งยา</v-tab>
            <v-tab key="labels">ฉลากยา</v-tab>
            <v-tab key="dispensings">จ่ายยา</v-tab>
            <v-tab key="transactions">ค่าใช้จ่าย</v-tab>
            <v-tab key="history">ประวัติการให้ยา</v-tab>
            <v-tab key="medicalrecord">เวชระเบียน</v-tab>
          </template>
          <template v-slot:items>
            <v-tab-item key="prescription">
              <v-document-viewer :documentQuery="{id: currentPrescription.documentId}" dialog dialogHeight="70vh"></v-document-viewer>
            </v-tab-item>
            <v-tab-item key="labels">
              <v-model-table model-name="labels" model-title="รายการฉลากยา"
                :model-api="['models','Pharmacy','PrescriptionsLabels']"
                :model-api-search="prescriptionsLabelsQuery"
                :api-with="['product','prescription']"
                :headers="labelsHeaders"
                :initial-data="labelsInitialData"
                show-select multiple-select
                ref="labelsTable"
              >
                <template v-slot:modelForm="{data,rules,isCreating,isDataChange}">
                  <v-container pa-0 fluid>
                    <v-row>
                      <v-col cols="12">
                        <v-prescription-item v-model="data"></v-prescription-item>
                      </v-col>
                      <v-hidden-field v-model="data.directionText" :item-value="data.directions" :hook="directionsToText" v-if="isDataChange"></v-hidden-field>
                      <v-col cols="12">
                        <v-textarea label="วิธีการใช้ยา" v-model="data.directionText" auto-grow></v-textarea>
                      </v-col>
                      <v-hidden-field v-model="data.cautionText" :item-value="data.cautions" :hook="cautionsToText" v-if="isDataChange"></v-hidden-field>
                      <v-col cols="12">
                        <v-textarea label="ข้อควรระวัง" v-model="data.cautionText" auto-grow></v-textarea>
                      </v-col>
                    </v-row>
                  </v-container>
                </template>
                <template v-slot:toolBarItem="props">
                  <v-btn @click="printLabels(props.allitems)" color="primary" class="ml-1">พิมพ์ฉลากยาทั้งหมด</v-btn>
                  <v-master-field v-model="currentStockId" 
                    :custom-api="['models','Stock','Stocks','query']"
                    custom-api-text="stockName" 
                    custom-api-value="id"
                    customApiEagerLoad
                    label="คลังสินค้า" show-code
                    single-line hide-details
                    class="ml-1"
                  ></v-master-field>
                  <v-spacer></v-spacer>
                </template>
                <template v-slot:toolBarButton="props">
                  <v-btn color="primary" @click="prescriptionTolabels" class="ml-1">ดึงข้อมูลใบสั่งยา</v-btn>
                </template>
                <template v-slot:item.directionText="props">
                  <v-label-direction :directions="props.item.directions" v-if="!props.item.directionText || props.item.directionText==''"></v-label-direction>
                  <span style="white-space: pre;" v-else>{{ props.item.directionText }}</span>
                </template>
                <template v-slot:item.cautionText="props">
                  <v-label-caution :cautions="props.item.cautions" v-if="!props.item.cautionText || props.item.cautionText==''"></v-label-caution>
                  <span style="white-space: pre;" v-else>{{ props.item.cautionText }}</span>
                </template>
                <template v-slot:body.append="props">
                  <tr>
                    <td><v-icon>mdi-subdirectory-arrow-right</v-icon></td>
                    <td :colspan="props.headers.length">
                      <v-btn @click="printLabels(props.selectedItems)" class="ml-1">พิมพ์ฉลากยา</v-btn>
                      <v-btn @click="labelsItemsToDispensings(props.selectedItems)" class="ml-1" v-if="currentStockId">จ่ายยา</v-btn>
                    </td>
                  </tr>
                </template>
              </v-model-table>
            </v-tab-item>
            <v-tab-item key="dispensings">
              <v-model-table model-name="dispensings" model-title="รายการจ่ายยา"
                :model-api="['models','Pharmacy','PrescriptionsDispensings']"
                :model-api-search="prescriptionsDispensingsQuery"
                api-with="product"
                api-order-by="created_at,desc"
                :headers="dispensingsHeaders"
                :initial-data="dispensingsInitialData"
                :dialog-width="($vuetify.breakpoint.mdAndUp) ? '50vw' : '80vw'"
                :insertable="!!currentStockId"
                ref="dispensingsTable"
              >
                <template v-slot:modelForm="{data,rules,isCreating}">
                  <v-container pa-0 fluid>
                    <v-row>
                      <v-col cols="10">
                        <v-master-field :rules="[rules.require()]" v-model="data.productCode" 
                          :custom-api="['models','Master','Products','query']"
                          custom-api-scope="availableAt"
                          :custom-api-scope-param="currentStockId"
                          custom-api-text="productName" 
                          custom-api-value="productCode" 
                          label="รายการ" show-code
                          customApiEagerLoad
                        ></v-master-field>
                      </v-col>
                      <v-col cols="2">
                        <v-text-field :rules="[rules.require(),rules.numeric()]" label="จำนวน" v-model="data.quantity"></v-text-field>
                      </v-col>
                      <v-col cols="4">
                        <v-master-field v-model="data.stockId" 
                          :custom-api="['models','Stock','Stocks','query']"
                          custom-api-text="stockName" 
                          custom-api-value="id"
                          customApiEagerLoad
                          label="คลังสินค้า" show-code
                        ></v-master-field>
                      </v-col>
                      <v-col cols="8">
                        <v-master-field v-model="data.lotNo" 
                          :custom-api="['models','Stock','StocksProducts','query']"
                          :filterText="(data.productCode) ? {productCode: data.productCode, stockId: data.stockId} : undefined"
                          custom-api-text="lotNo" custom-api-value="lotNo"
                          waitForFilter waitForFilterText="กรุณาเลือกรายการสินค้า" customApiEagerLoad
                          label="Lot No." clearable
                        ></v-master-field>
                      </v-col>
                    </v-row>
                  </v-container>
                </template>
                <template v-slot:toolBarItem="props">
                  <v-btn color="primary" @click="chargeAll" class="ml-1">คิดค่าใช้จ่าย</v-btn>
                  <v-btn color="primary" @click="dispenseAll" class="ml-1">จ่ายยาทั้งหมด</v-btn>
                  <v-btn color="error" @click="unchargeAll" class="ml-1">ยกเลิกค่าใช้จ่าย</v-btn>
                  <v-master-field v-model="currentStockId" 
                    :custom-api="['models','Stock','Stocks','query']"
                    custom-api-text="stockName" 
                    custom-api-value="id"
                    customApiEagerLoad
                    label="คลังสินค้า" show-code
                    single-line hide-details
                    class="ml-1"
                  ></v-master-field>
                  <v-spacer></v-spacer>
                </template>
                <template v-slot:toolBarButton="props" v-if="currentStockId">
                  <v-btn color="primary" @click="labelsToDispensings" class="ml-1">ดึงข้อมูลฉลากยา</v-btn>
                </template>
                <template v-slot:item.isNotCharge="props">
                  {{ props.item.isNotCharge ? 'ไม่คิด' : 'คิด'}}
                </template>
                <template v-slot:item.action="props">
                  <v-btn @click.stop="dispense(props.item,props.actions)" class="ml-1" color="primary" v-if="props.item.status!='dispensed'">จ่ายยา</v-btn>
                  <v-btn @click.stop="setNotCharge(props.item,props.actions)" class="ml-1" color="primary" v-if="!props.item.transactionId && !props.item.isNotCharge">ไม่คิดเงิน</v-btn>
                  <v-btn @click.stop="setCharge(props.item,props.actions)" class="ml-1" color="primary" v-if="!props.item.transactionId && props.item.isNotCharge">คิดเงิน</v-btn>
                  <v-btn @click.stop="props.actions.deleteData(props.item)" color="error" class="ml-1" v-if="!props.item.transactionId && (props.item.status!='dispensed' || $permission('pharmacy-undispensed'))"><v-icon class="mx-1">$vuetify.icons.delete</v-icon> ลบ</v-btn>
                </template>
              </v-model-table>
            </v-tab-item>
            <v-tab-item key="transactions">
              <v-encounter-transaction ref="transactionsTable" :hn="currentPrescription.hn" :encounterId="currentPrescription.encounterId" :prescriptionId="currentPrescription.id" :read-only="currentPrescription.status=='completed'"></v-encounter-transaction>
            </v-tab-item>
            <v-tab-item key="history">
              <v-model-table model-name="labels" model-title="ประวัติการจ่ายยา"
                :model-api="['models','Pharmacy','PrescriptionsLabels']"
                :model-api-search="prescriptionsLabelsHistoryQuery"
                :api-with="['product','prescription']"
                api-orderby="prescriptionId,desc"
                group-by="prescriptionId" group-desc
                :headers="labelsHistoryHeaders"
                :insertable="false"
                readonly
              >
                <template v-slot:toolBarItem="props">
                  <v-master-field v-model="currentHistoryProductCode" 
                    :custom-api="['models','Master','Products','query']"
                    custom-api-scope="stockable"
                    custom-api-text="productName" 
                    custom-api-value="productCode" 
                    label="รายการ" show-code clearable single-line hide-details
                  ></v-master-field>
                  <v-spacer></v-spacer>
                </template>
                <template v-slot:group.header="props">
                  <td :colspan="props.headers.length">
                    <label class="font-weight-bold">{{ props.items[0].prescription.created_at }}</label> (Last schedule : {{ props.items[0].prescription.scheduleDate }})
                  </td>
                </template>
                <template v-slot:item.directionText="props">
                  <v-label-direction :directions="props.item.directions" v-if="!props.item.directionText || props.item.directionText==''"></v-label-direction>
                  <span style="white-space: pre;" v-else>{{ props.item.directionText }}</span>
                </template>
                <template v-slot:item.cautionText="props">
                  <v-label-caution :cautions="props.item.cautions" v-if="!props.item.cautionText || props.item.cautionText==''"></v-label-caution>
                  <span style="white-space: pre;" v-else>{{ props.item.cautionText }}</span>
                </template>
              </v-model-table>
            </v-tab-item>
            <v-tab-item key="medicalrecord">
              <v-document-list :hn="currentPrescription.hn" :encounterId="currentPrescription.encounterId" folder="default"></v-document-list>
            </v-tab-item>
          </template>
        </v-tabs-group>
      </v-col>
      <v-col cols="12" v-else>
        <v-tabs-group>
          <template v-slot:tabs>
            <v-tab>ใบสั่งยารอจ่าย</v-tab>
            <v-tab>ใบสั่งยาเรียบร้อย</v-tab>
          </template>
          <template v-slot:items>
            <v-tab-item>
              <v-model-table model-name="Prescriptions" model-title="รายการใบสั่งยารอจ่าย"
                :model-api="['models','Pharmacy','Prescriptions']"
                :model-api-search="prescriptionsQuery"
                :headers="headers"
                :initial-data="prescriptionInitialData"
                :insertable="!!currentEncounter"
                hideInsertButton @dataCreated="openPrescription"
                :dialog-width="($vuetify.breakpoint.mdAndUp) ? '50vw' : '80vw'"
                ref="prescriptionsTable"
              >
                <template v-slot:modelForm="{data,rules,isCreating}">
                  <v-container pa-0 fluid>
                    <v-row>
                      <v-col cols="12">
                        <v-date-field v-model="data.scheduleDate" label="วันที่จ่ายยา"></v-date-field>
                      </v-col>
                    </v-row>
                  </v-container>
                </template>
                <template v-slot:toolBarButton="props" v-if="!!currentEncounter">
                  <v-btn color="primary" @click="newPrescription(props)"><v-icon>add</v-icon></v-btn>
                </template>
                <template v-slot:item.patient.name_th="props">
                  <v-label-name :name-data="props.item.patient.name_th"></v-label-name>
                </template>
                <template v-slot:item.action="props">
                  <v-btn @click.stop="currentPrescriptionId=props.item.id" color="primary"><v-icon class="mx-1">mdi-circle-edit-outline</v-icon> เปิด</v-btn>
                  <v-btn @click.stop="props.actions.deleteData(props.item)" color="error" class="mx-1" v-if="props.item.status=='new'"><v-icon class="mx-1">$vuetify.icons.delete</v-icon> ลบ</v-btn>
                </template>
              </v-model-table>
            </v-tab-item>
            <v-tab-item>
              <v-model-table model-name="Prescriptions" model-title="รายการใบสั่งยาเรียบร้อย"
                :model-api="['models','Pharmacy','Prescriptions']"
                :model-api-search="prescriptionsCompleteQuery"
                :headers="headers"
                :insertable="false" serverPagination
                ref="prescriptionsCompleteTable"
              >
                <template v-slot:item.patient.name_th="props">
                  <v-label-name :name-data="props.item.patient.name_th"></v-label-name>
                </template>
                <template v-slot:item.action="props">
                  <v-btn @click.stop="currentPrescriptionId=props.item.id" color="primary"><v-icon class="mx-1">mdi-circle-edit-outline</v-icon> เปิด</v-btn>
                </template>
              </v-model-table>
            </v-tab-item>
          </template>
        </v-tabs-group>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import encounterLoader from '@/components/mixins/encounterLoader'
import printLabel from '@/components/mixins/printLabel'
import helperPrescription from '@/modules/helperPrescription'
import helperItem from '@/modules/helperItem'
import api from 'tantee-common/lib/api'

export default {
  mixins: [encounterLoader,printLabel],
  data: ()=>({
    currentEncounterId: undefined,
    currentPrescriptionId: undefined,
    currentPrescription: undefined,
    currentStockId: undefined,
    currentHistoryProductCode: undefined,
    headers: [
      {
        text: 'วันที่ใบสั่งยา',
        value: 'created_at',
        class: 'body-2',
        width: '200px',
      },
      {
        text: 'HN',
        value: 'patient.hn',
        class: 'body-2',
        width: '150px',
      },
      {
        text: 'ชื่อ-นามสกุล',
        value: 'patient.name_th',
        class: 'body-2'
      },
      {
        text: 'แพทย์',
        value: 'doctor.nameTH',
        class: 'body-2'
      },
      {
        text: 'วันที่ให้ยาครั้งต่อไป',
        value: 'scheduleDate',
        class: 'body-2'
      },
      {
        text: 'สถานะ',
        value: 'status',
        class: 'body-2'
      },
      {
        text: 'Action',
        value: 'action',
        align: 'center',
        width: '250px',
        sortable: false,
        class: 'body-2'
      }
    ],
    labelsHeaders: [
      {
        text: 'รหัสสินค้า',
        value: 'productCode',
        class: 'body-2',
        width: '150px'
      },
      {
        text: 'รายการ',
        value: 'product.productName',
        class: 'body-2'
      },
      {
        text: 'จำนวน',
        value: 'quantity',
        class: 'body-2'
      },
      {
        text: 'วิธีการใช้ยา',
        value: 'directionText',
        class: 'body-2'
      },
      {
        text: 'ข้อควรระวัง',
        value: 'cautionText',
        class: 'body-2'
      },
      {
        text: 'Action',
        value: 'action',
        align: 'center',
        width: '250px',
        sortable: false,
        class: 'body-2'
      }
    ],
    labelsHistoryHeaders: [
      {
        text: 'รหัสสินค้า',
        value: 'productCode',
        class: 'body-2',
        width: '150px'
      },
      {
        text: 'รายการ',
        value: 'product.productName',
        class: 'body-2'
      },
      {
        text: 'จำนวน',
        value: 'quantity',
        class: 'body-2'
      },
      {
        text: 'วิธีการใช้ยา',
        value: 'directionText',
        class: 'body-2'
      },
      {
        text: 'ข้อควรระวัง',
        value: 'cautionText',
        class: 'body-2'
      },
    ],
    dispensingsHeaders: [
      {
        text: 'วัน-เวลา',
        value: 'created_at',
        class: 'body-2'
      },
      {
        text: 'รหัสสินค้า',
        value: 'productCode',
        class: 'body-2',
        width: '150px'
      },
      {
        text: 'รายการ',
        value: 'product.productName',
        class: 'body-2'
      },
      {
        text: 'จำนวน',
        value: 'quantity',
        class: 'body-2'
      },
      {
        text: 'คิดเงิน',
        value: 'isNotCharge',
        class: 'body-2',
        width: '100px'
      },
      {
        text: 'สถานะ',
        value: 'status',
        class: 'body-2'
      },
      {
        text: 'User',
        value: 'created_by',
        class: 'body-2'
      },
      {
        text: 'Action',
        value: 'action',
        align: 'center',
        width: '350px',
        sortable: false,
        class: 'body-2'
      }
    ],
  }),
  computed: {
    prescriptionsLabelsQuery() {
      return {
        prescriptionId: this.currentPrescription.id
      }
    },
    prescriptionsLabelsHistoryQuery() {
      return {
        'Prescription$hn': this.currentPrescription.hn,
        'productCode': this.currentHistoryProductCode
      }
    },
    prescriptionsDispensingsQuery() {
      return {
        prescriptionId: this.currentPrescription.id
      }
    },
    prescriptionsQuery() {
      return {
        'hn': (this.currentEncounter) ? this.currentEncounter.hn : undefined,
        'encounterId': (this.currentEncounter) ? this.currentEncounter.encounterId : undefined,
        'where#status#<>': 'completed'
      }
    },
    prescriptionsCompleteQuery() {
      return {
        'hn': (this.currentEncounter) ? this.currentEncounter.hn : undefined,
        'encounterId': (this.currentEncounter) ? this.currentEncounter.encounterId : undefined,
        'status': 'completed'
      }
    },
    prescriptionInitialData() {
      if (this.currentEncounter) {
        return {
          'hn': this.currentEncounter.hn,
          'encounterId': this.currentEncounter.encounterId,
        }
      } else {
        return undefined
      }
    },
    labelsInitialData() {
      return {
        'prescriptionId': this.currentPrescription.id,
      }
    },
    dispensingsInitialData() {
      return {
        'prescriptionId': this.currentPrescription.id,
        'stockId': this.currentStockId
      }
    }
  },
  watch: {
    currentPrescriptionId: function(newVal) {
      helperItem.findItem(['models','Pharmacy','Prescriptions'],newVal).then((returnData)=>{
        this.currentPrescription = returnData
        this.currentEncounterId = this.currentPrescription.encounterId
      }).catch((e)=>{
        this.currentPrescription = undefined
        this.currentEncounterId = undefined
        void e
      })
    }
  },
  methods: {
    onEncounterUnloaded() {
      this.reset()
      this.currentPrescriptionId = undefined
      this.currentPrescription = undefined
    },
    directionsToText(directions) {
      return helperPrescription.directionsToText(directions)
    },
    cautionsToText(cautions) {
      return helperPrescription.cautionsToText(cautions)
    },
    closePartial() {
      if (this.currentPrescription) {
        this.currentPrescription.status = 'partial'
        helperItem.updateItem(['models','Pharmacy','Prescriptions'],this.currentPrescription).then((returnData)=>{
          this.onEncounterUnloaded()
        })
      }
    },
    closeComplete() {
      if (this.currentPrescription) {
        this.currentPrescription.status = 'completed'
        helperItem.updateItem(['models','Pharmacy','Prescriptions'],this.currentPrescription).then((returnData)=>{
          this.onEncounterUnloaded()
        })
      }
    },
    newPrescription(actions) {
      actions.onCreateData(this.prescriptionInitialData)
    },
    openPrescription(prescription) {
      this.currentPrescriptionId = prescription[0].id
    },
    chargeAll() {
      let postData = {
        prescriptionId: this.currentPrescription.id,
      }

      let chargePromise = api.post(['Pharmacy','PrescriptionController','chargeDispensingAll'],postData).then(()=>{
        if (this.$refs.dispensingsTable) this.$refs.dispensingsTable.loadData()
        if (this.$refs.transactionsTable) this.$refs.transactionsTable.reload()
      }).catch((e)=>{
        void e
      })

      this.$loading('Charging',chargePromise)
    },
    unchargeAll() {
      let postData = {
        prescriptionId: this.currentPrescription.id,
      }

      let chargePromise = api.post(['Pharmacy','PrescriptionController','unchargeDispensingAll'],postData).then(()=>{
        if (this.$refs.dispensingsTable) this.$refs.dispensingsTable.loadData()
        if (this.$refs.transactionsTable) this.$refs.transactionsTable.reload()
      }).catch((e)=>{
        void e
      })

      this.$loading('Charging',chargePromise)
    },
    dispenseAll() {
      let postData = {
        prescriptionId: this.currentPrescription.id,
      }

      let dispensePromise = api.post(['Pharmacy','PrescriptionController','dispensePrescription'],postData).then(()=>{
        if (this.$refs.dispensingsTable) this.$refs.dispensingsTable.loadData()
      }).catch((e)=>{
        void e
      })

      this.$loading('Dispensing',dispensePromise)
    },
    dispense(item,action) {
      item.status = 'dispensed'
      action.saveData(item)
    },
    setCharge(item,action) {
      item.isNotCharge = false
      action.saveData(item)
    },
    setNotCharge(item,action) {
      item.isNotCharge = true
      action.saveData(item)
    },
    prescriptionTolabels() {
      let postData = {
        prescriptionId: this.currentPrescription.id,
      }
      let loadingPromise = api.post(['Pharmacy','PrescriptionController','labelsFromPrescription'],postData).then(()=>{
        if (this.$refs.labelsTable) this.$refs.labelsTable.loadData()
      }).catch((e)=>{
        void e
      })

      this.$loading('Importing',loadingPromise)
    },
    labelsToDispensings() {
      let postData = {
        prescriptionId: this.currentPrescription.id,
        stockId: this.currentStockId
      }
      let loadingPromise = api.post(['Pharmacy','PrescriptionController','dispensingFromLabels'],postData).then(()=>{
        if (this.$refs.dispensingsTable) this.$refs.dispensingsTable.loadData()
      }).catch((e)=>{
        void e
      })

      this.$loading('Importing',loadingPromise)
    },
    labelsItemsToDispensings(labelsItems) {
      let postData = {
        labelsItems: labelsItems,
        stockId: this.currentStockId
      }
      let loadingPromise = api.post(['Pharmacy','PrescriptionController','dispensingFromLabelsItems'],postData).then(()=>{
        if (this.$refs.dispensingsTable) this.$refs.dispensingsTable.loadData()
        this.$notify('จ่ายยาจากฉลากยาเรียบร้อย')
      }).catch((e)=>{
        void e
      })

      this.$loading('Importing',loadingPromise)
    },
    async printLabels(items) {
      let labelsData = []
      let itemNo = 1;
      for (const item of items) {
        let tmpLabel = {
          'ProductText': (item.productText) ? item.productText : item.product.productName,
          'DirectionText': (item.directionText) ? item.directionText : await helperPrescription.directionsToText(item.directions),
          'CautionText': (item.cautionText) ? item.cautionText : await helperPrescription.cautionsToText(item.cautions),
          'ShelfLocation': (item.product && item.product.specification && item.product.specification.shelfLocation) ? item.product.specification.shelfLocation : '',
          'PrescriptionDoctorName': (item.prescription && item.prescription.doctor && item.prescription.doctor.nameTH) ? item.prescription.doctor.nameTH : '',
          'Quantity': item.quantity,
          'RDUQrCode': item.prescriptionId,
          'ItemNo': itemNo,
          'ItemTotal': items.length
        }
        itemNo = itemNo + 1;
        labelsData.push(tmpLabel)
      }
      this.printLabel('prescription_opd',this.currentEncounter,labelsData)
    }
  },
  mounted() {
    let location = this.$store.getters.getConfig('location')
    if (location && location.stockId) this.currentStockId = location.stockId
  }
}
</script>

<style lang="css" scoped>
</style>
